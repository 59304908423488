<template>
    <div id="MemberSystemDashboard" class="serif dashboard-style">
        <aside class="hide-mb">
            <router-link :to="{ name: 'Home' }">
                <img :src="require('@/assets/logo/vertical-logo.png')" />
            </router-link>
            <ul class="f16 bold">
                <li>
                    <router-link :to="{ name: 'MemberSystemAccount' }"
                        ><ElIcon><User /></ElIcon>アカウント</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'CourseList' }"
                        ><ElIcon><Notebook /></ElIcon>真理学修</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'MemberSystemStripe' }"
                        ><ElIcon><Money /></ElIcon
                        >おつなぎ徳積みの理</router-link
                    >
                </li>
                <li>
                    <a
                        href="https://reserva.be/daishizenkai/reserve"
                        target="_blank"
                        ><ElIcon><Calendar /></ElIcon>予約<el-text
                            class="f14 f12-mb"
                        >
                            ※別サイトを開く</el-text
                        >
                    </a>
                </li>
                <li>
                    <router-link :to="{ name: 'ChangePassword' }"
                        ><ElIcon><Lock /></ElIcon>パスワードの変更</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'CourseForm' }"
                        ><ElIcon><Service /></ElIcon>お問い合わせ</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'Home' }" @click="logout">
                        <ElIcon><Back /></ElIcon>ログアウト
                    </router-link>
                </li>
            </ul>
        </aside>
        <div class="hide">
            <div id="nav-bar-burger" @click="toggle">
                <i class="top"></i>
                <i class="front"></i>
                <i class="back"></i>
                <i class="bottom"></i>
                <p class="f12-mb serif">MENU</p>
            </div>
            <ElDrawer
                v-model="showDrawer"
                title="test"
                :with-header="false"
                size="70%"
            >
                <aside>
                    <router-link :to="{ name: 'Home' }">
                        <img
                            :src="require('@/assets/logo/vertical-logo.png')"
                        />
                    </router-link>
                    <ul class="f16 bold">
                        <li>
                            <router-link
                                @click="toggle"
                                :to="{ name: 'MemberSystemAccount' }"
                                ><ElIcon><User /></ElIcon
                                >アカウント</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                @click="toggle"
                                :to="{ name: 'CourseList' }"
                                ><ElIcon><Notebook /></ElIcon
                                >真理学修</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                @click="toggle"
                                :to="{ name: 'MemberSystemStripe' }"
                                ><ElIcon><Money /></ElIcon
                                >おつなぎ徳積みの理</router-link
                            >
                        </li>
                        <li>
                            <a
                                href="https://reserva.be/daishizenkai/reserve"
                                target="_blank"
                                ><ElIcon><Calendar /></ElIcon>予約<el-text
                                    class="f14 f12-mb"
                                >
                                    ※別サイトを開く</el-text
                                >
                            </a>
                        </li>
                        <li>
                            <router-link
                                @click="toggle"
                                :to="{ name: 'ChangePassword' }"
                                ><ElIcon><Lock /></ElIcon
                                >パスワードの変更</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                @click="toggle"
                                :to="{ name: 'CourseForm' }"
                                ><ElIcon><Service /></ElIcon
                                >お問い合わせ</router-link
                            >
                        </li>
                        <li>
                            <router-link :to="{ name: 'Home' }" @click="logout">
                                <ElIcon><Back /></ElIcon>ログアウト
                            </router-link>
                        </li>
                    </ul>
                </aside>
            </ElDrawer>
        </div>
        <main class="f20">
            <ElBreadcrumb separator="/">
                <ElBreadcrumb-item
                    v-for="item in route.meta.breadcrumb"
                    :key="item.title"
                >
                    <router-link v-if="item.routeUrl" :to="item.routeUrl">
                        {{ item.title }}
                    </router-link>
                    <span v-else>{{ item.title }}</span>
                </ElBreadcrumb-item>
                <ElBreadcrumb-item v-if="route.params.subCategory"
                    >{{ route.params.subCategory }}
                </ElBreadcrumb-item>
            </ElBreadcrumb>
            <router-view class="container"></router-view>
        </main>
    </div>
</template>
<script>
export default {
    name: 'MemberSystemDashboard',
}
</script>
<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
    User,
    Notebook,
    Money,
    Lock,
    Back,
    Calendar,
    Service,
} from '@element-plus/icons-vue'
import { getUserDonateDetail } from '@/service/api'
import { ElMessage } from 'element-plus'
const store = useStore()
const route = useRoute()
const router = useRouter()
onMounted(async () => {
    let res = await getUserDonateDetail('createdAt,desc')
    store.commit('updateDonateRecord', res.data)
})

const showDrawer = ref(false)
function toggle() {
    showDrawer.value = !showDrawer.value
}
function logout() {
    store.commit('logout')
    ElMessage.success('ログアウトに成功しました。')
    router.push({ name: 'MemberSystemLogin' })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
</style>
